.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 550px;
    width: 550px;
  }
  
  .icon-preview {
    width: 100px;
    height: 100px;
    filter: invert(1);
    object-fit: contain;
  }
  
  .title-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
  }
  
  .card_title,
  .indicator {
    color: white;
    margin: 0px;
    font-family: "roboto", sans-serif;
    text-align: center;
  }
  
  .indicator {
    color: lightgrey;
    margin-top:10px;
  }

  .card_title {
    font-size: 1.5em;
  }
  
  .card {
    position: relative;
    top: 100px;
    left: 0px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 300px;
    width: 200px;
    height: 200px;
  }

  .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
    left: 25px;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    transition: opacity 0.4s ease-out, transform 0.2s ease-out 0.2s;
    backdrop-filter: blur(10px);
    z-index: 1;
  }
  
  .items {
    position: relative;
    top: -110px;
    left: 0px;
    height: 200px;
    width: 200px;
    z-index: -1;
  }
  
   .content:nth-child(4) {
    transform: translate(141px, 141px);
  }
  
  .content:nth-child(8) {
    transform: translate(-141px, -141px);
  }
  
  .content:nth-child(6) {
    transform: translate(-141px, 141px);
  }
  
  .content:nth-child(2) {
    transform: translate(141px, -141px);
  }
  
  .content:nth-child(1) {
    transform: translate(0px, -200px);
  }
  
  .content:nth-child(5) {
    transform: translate(0px, 200px);
  }
  
  .content:nth-child(3) {
    transform: translate(200px, 0px);
  }
  
  .content:nth-child(7) {
    transform: translate(-200px, 0px);
  }
  
  .items {
    animation: rotation 300s infinite linear;
  }
  
  .content > * {
    animation: inv_rotation 300s infinite linear;
  }
  
  @keyframes inv_rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  