html {
  scroll-behavior: smooth !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar{
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.star {
  margin-top: 10px;
  margin-left:2px;
  margin-right:2px;
  height:10px;
  width:10px;
  background-color: dimgray;
  border-radius: 50px;
}

.star-checked {
  background-color: lightgray;
}

.content-container{
  flex: 1 1 auto;
}
.footer-container{
  flex: 0 1 auto;
}

.swiper-slide {
  display: flex;
  flex-direction : column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

@media only screen and (min-width:0px){
  .swiper{
      width:90vw;
  }
  .skill-logo{
    height: 60px;
  }
  .skill-logo-alternate {
    height: 30px;
  }
}

@media only screen and (min-width:780px){
  .swiper{
      width:50%;
  }
  .skill-logo{
    height: 100px;
  }
  .skill-logo-alternate {
    height: 40px;
    margin-bottom: 10px;
  }
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none;}

.swiper-wrapper .swiper-slide {
  height: unset;
}

.card-hoverable:hover{
  transition: all 200ms ease-in-out;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.05);
  transform: scale(1.02);
  border: none;
}

.swiper-pagination{
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  --swiper-pagination-bullet-inactive-color : white
  
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #ffffff !important;
  --swiper-pagination-bullet-width: 15px;
  --swiper-pagination-bullet-height: 15px;
}

.timeline-main-wrapper{
  overscroll-behavior:auto !important;
}

div[mode]{
  display: none !important;
}

